<template>
  <div class="activity-datail">
    <div class="activity-datail-content" v-html="detailData.content"></div>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  export default {
    name: "activityDatail",
    data(){
      return {
        detailData: {},
      }
    },
    computed: {
      ...mapState({
        activityList: state => state.activityList
      })
    },
    mounted() {
      this.initData()
    },
    methods: {
      ...mapActions({
        getBannerList: 'getBannerList',
      }),
      async initData(){
        if(!this.activityList.length){
          await this.getBannerList()
        }
        console.log('canshu ',this.$route.query.id);
        console.log('activityList', this.activityList);
        const findData = this.activityList.find(item=>item.id == this.$route.query.id)
        if(findData){
          // 11-公众号推文，12-群推送，21-站点数据，22-车还招租，23-蛋卷的士之家，24-短信推送
          // console.log('类型', this.$route.query.type);
          if(this.$route.query.type){
            let pattern = /\/#\/onlineAgreement"/g;
            findData.content = findData.content.replace(pattern, `/#/onlineAgreement?type=${this.$route.query.type}"`);
          }
          this.detailData = findData
          //设置页面标题
          document.title = findData.title || ''
        }
        console.log('详情数据', this.detailData);
        
      }
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep{
    .activity-datail-content{
      img{
        display: block !important;
        width: 100% !important;
        height: auto !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
    
  .activity-datail{
    
  }
</style>